import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="content">
        <h1>EasyOps</h1>
        <h2>Next Generation of Node Deployment</h2>
        <p>Under Development</p>
        <p>California - USA</p>
      </div>
    </div>
  );
}

export default App;

